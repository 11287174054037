import { blue, green, indigo, orange, pink, purple, teal } from "@mui/material/colors";

export const MENU_ITEMS = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sales Page", url: "/sales-page", icon: "list_alt" },
    { action: "Sales Chart", url: "/sales-chart-page", icon: "bar_chart" },
    { action: "Group Sales", url: "/group-sales-page", icon: "groups" },
    { action: "Users Page", url: "/users-page", icon: "face" },
    { action: "Calendar Page", url: "/calendar-page", icon: "calendar_month" },
    { action: "Projects Page", url: "/projects-page", icon: "source", role: "Super Admin" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign Out", url: "/sign-out", icon: "logout" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Seed Sales", url: "/seed-sales", icon: "forest", role: "Super Admin" },
    { action: "Recent Deals", url: "/recent-deals-page", icon: "forest", role: "Super Admin" },
    { action: "Recent Deals", url: "/recent-deals-page", icon: "forest", role: "Loan Consultant" },
    { action: "Seed Agents", url: "/seed-agents", icon: "face", role: "Super Admin" },
    { action: "Test Page", url: "/test-page", icon: "biotech", role: "Super Admin" },
    { action: "Populate Involve", url: "/populate-involve", icon: "biotech", role: "Super Admin" },
    { action: "Populate Number of Involve", url: "/populate-number-of-involve-page", icon: "biotech", role: "Super Admin" },
    { action: "Divider", url: "/", icon: "", role: "Super Admin" },
    { action: "Training Page", url: "/training-page", icon: "biotech", role: "Super Admin" },
    { action: "Playground Page", url: "/playground-page", icon: "biotech", role: "Super Admin" },
    { action: "Q&A Page", url: "/qa-page", icon: "biotech", role: "Super Admin" }
];

export const MENU_ITEMS_SIGNED_OUT = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign In", url: "/sign-in", icon: "login" }
];

export const COPYRIGHT_LINK = "https://www.eliteonegroup.com";

export const WEBSITE_NAME = "www.eliteonegroup.com";

export const APP_TITLE = "Eliteone";

export const HOMEPAGE_ITEMS = [
    { description: "View all Sales", title: "Sales Page", color: orange[700], link: "/sales-page" },
    { description: "View Team Sales Chart", title: "Sales Chart", color: pink[700], link: "/sales-chart-page" },
    { description: "View Sales Conversion Chart", title: "Conversions Chart", color: green[600], link: "/conversion-chart-page" },
    { description: "divider" },
    { description: "View Groups Sales", title: "Groups Sales", color: purple[700], link: "/group-sales-page" },
    { description: "divider" },
    { description: "View Projects Chart", title: "Projects Chart", color: teal[800], link: "/projects-chart-page" },
    { description: "divider" },
    { description: "View Campaigns Page", title: "Campaigns Page", color: indigo[500], link: "/campaigns-page" },
    { description: "divider" },
    { description: "View all Members", title: "Users Page", color: blue[700], link: "/users-page" }
];
